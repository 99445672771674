const UtilityCloseIframe = ({isIframe, parentDomain}) => {
    if(isIframe && parentDomain) return (
        <span
            className="cursor-pointer"
            style={{ position: 'fixed', top: 10, right: 10, zIndex: 99 }}
            onClick={() => {
                window.parent.postMessage({
                    type: 'zapReportsReviews',
                    event: 'onClose'
                }, parentDomain)
            }} 
        >
            <img alt="back" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADYSURBVHgB7VQ7FoMwDJNDh44ZU6ZcpSfnCD0CjIyMXSC1oR/6AvnQ16VFYyxblm0AdvwztAaMjfOEI9zM4upQXlRR1mERY4VDzF0TUcuJR+2c0yBwAVUti5gpxhwIl3OQB2OpONXsxPlOps4lJpyQS0JUhCoi4i7RDP1wltdH5+yycb3jt7bZKOCL3LOSimdgNq6EscyhkAiamaUU409uFK9rkbGMSW87CY8oumR/oZzkLX7THkKnGDrhj4vniax9yZ0jdOFTbMfYyGEucO2QB/vNn92OX8INmzZ377iksSUAAAAASUVORK5CYII=" />
        </span>
    )

    return <></>
}

export default UtilityCloseIframe;