import { Container } from "reactstrap";

const Home = () => (
    <Container className="my-6 text-center">
        <h1>Page Not Found</h1>
        <h4>We're sorry, the page you are looking for cannot be found.</h4>
    </Container>
)

export default Home

