import { useState, useCallback } from 'react'

import { Card, CardFooter, CardHeader, CardTitle, Col, Container, Row, CardBody, FormGroup, Input } from "reactstrap";

import ReviewBadIcon from '../_images/ReviewBad.svg'
import SubmittedIcon from '../_images/Submitted.svg'

import _review_outcomes from '_functions/review_outcomes';

const ReviewBad = ({company, reviewLink, parentDomain, companyNameColor}) => {

    const [errs, setErrs] = useState([]);
    const [submitted, setSubmitted] = useState(false);

    const [givenName, setGivenName] = useState('');
    const [familyName, setFamilyName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const onLeaveReview = useCallback(async () => {

        if(window.location !== window.parent.location) {
            window.parent.postMessage({
                type: 'zapReportsReviews',
                event: 'onClose'
            }, parentDomain)
            window.open(reviewLink.redirect_url);
        } else {
            window.location.href = reviewLink.redirect_url;
        }

    }, [reviewLink.redirect_url, parentDomain])

    const onSubmit = async () => {

        let _errs = [];

        if(!givenName || (givenName && !givenName.trim())) _errs.push(givenName);
        if(!familyName || (familyName && !familyName.trim())) _errs.push(familyName);
        if(!email || (email && !email.trim())) _errs.push(email);
        if(!message || (message && !message.trim())) _errs.push(message);

        setErrs(_errs);
        if(_errs.length) return;

        _review_outcomes.create({
            company: company._id,
            review_link: reviewLink._id,
            status: 'gated',
            submission: {
                given_name: givenName,
                family_name: familyName,
                email,
                message
            }
        })

        setSubmitted(true)

    }

    if(submitted) return (
        <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">
        
                    <Card className="bg-white border-0 mb-0 position-relative" >
        
                        <img src={SubmittedIcon} className="bg-white flow-icon" alt="..." />
        
                        <CardHeader>
                            <CardTitle className="mb-0 flow-icon-header">Thank You!</CardTitle>
                        </CardHeader>
        
                        <CardFooter>
                            <p className="mb-0">We are always looking for ways to serve you better and your feedback is greatly appreciated. </p>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </Container>
                    
    )

    return (
        <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">
        
                    <Card className="bg-white border-0 mb-0 position-relative" >
        
                        <img src={ReviewBadIcon} className="bg-white flow-icon" alt="..." />
        
                        <CardHeader>
                            <CardTitle className="mb-0 flow-icon-header">Help Us Improve</CardTitle>
                        </CardHeader>
        
                        <CardHeader>
                            <p className="mb-0">We appreciate your feedback and would love to know how we can serve you better. Please let us know below. </p>
                        </CardHeader>
        
                        <CardBody className="">
                            <Row>
                                <Col xs={6}>
                                    <FormGroup>
                                        <label className="form-control-label">First Name *</label>
                                        <Input 
                                            type="text"
                                            value={givenName}
                                            invalid={errs.includes(givenName)}
                                            valid={givenName}
                                            onChange={(e) => setGivenName(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={6}>
                                    <FormGroup>
                                        <label className="form-control-label">Last Name *</label>
                                        <Input 
                                            type="text"
                                            value={familyName}
                                            invalid={errs.includes(familyName)}
                                            valid={familyName}
                                            onChange={(e) => setFamilyName(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <FormGroup>
                                <label className="form-control-label">Email *</label>
                                <Input 
                                    type="text"
                                    value={email}
                                    invalid={errs.includes(email)}
                                    valid={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </FormGroup>

                            <FormGroup>
                                <label className="form-control-label">How Can We Improve? *</label>
                                <Input 
                                    style={{minHeight: 100}}
                                    type="textarea"
                                    value={message}
                                    invalid={errs.includes(message)}
                                    valid={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                            </FormGroup>

                        </CardBody>
                        
                        <CardFooter>
                            <button onClick={onSubmit} className="btn btn-success btn-block btn-lg"> Leave Your Review <i className="fas fa-paper-plane ml-2 " /></button>
                        </CardFooter>
        
                    </Card>

                    <p  className="text-sm text-center mt-3" style={{color: companyNameColor}} >
                        <span>  If you wish to leave a full review please <span className="text-underline cursor-pointer" onClick={onLeaveReview}>Click HERE</span></span>
                    </p>

                </Col>
            </Row>
        </Container>
    )
}

export default ReviewBad;