import Reviews from '../pages/Reviews';
import Home from '../pages/Home/index';

const routes = [
    {
        path: ":review_link",
        component: Reviews,
    },
    {
        path: "",
        component: Home,
    },
]

export default routes;