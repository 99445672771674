import { useEffect, useCallback } from 'react'

import { Card, CardFooter, CardHeader, CardTitle, Col, Container, Row, CardBody } from "reactstrap";

import GateIcon from '../_images/Gate.svg'

const Gate = ({onStarClicked, disabled}) => {

    const onLeave = useCallback(() => {
        const stars = document.querySelectorAll('.archk-star');
        for (let i = 0; i < stars.length; i++) {
            const star = stars[i];
            star.classList.remove('text-yellow')
        }
    }, [])

    const onHover = useCallback((e) => {
        const number = parseInt(e.target.getAttribute('data-number'))

        const stars = document.querySelectorAll('.archk-star');

        for (let i = 0; i < stars.length; i++) {
            const star = stars[i];
            const startNum = parseInt(star.getAttribute('data-number'))
            if(startNum < number + 1) {
                star.classList.add('text-yellow')
            } else {
                star.classList.remove('text-yellow')
            }
        }
    }, [])

    useEffect(() => {

        const wrapper = document.getElementById('archk-star-wrapper')
        const stars = document.querySelectorAll('.archk-star');

        for (let i = 0; i < stars.length; i++) {
            const star = stars[i];
            // used for desktop
            star.addEventListener('mouseover', onHover)
            // use for mobile
            star.addEventListener('onclick', onHover)
        }

        wrapper.addEventListener('mouseleave', onLeave)

        return () => {
            for (let i = 0; i < stars.length; i++) {
                const star = stars[i];
                star.removeEventListener('mouseover', onHover)
                star.removeEventListener('onclick', onHover)
            }
        }

    }, [onHover, onLeave])

    return (
        <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">
        
                    <Card className="bg-white border-0 mb-0 position-relative" >
        
                        <img src={GateIcon} className="bg-white flow-icon" alt="..." />
        
                        <CardHeader>
                            <CardTitle className="mb-0 flow-icon-header">Share Your Success!</CardTitle>
                        </CardHeader>
        
                        <CardHeader>
                            <p className="mb-0"> We would be honored if you could take a moment to let us know how your experience with us has been so far.</p>
                        </CardHeader>
        
                        <CardBody className="text-center">
                            <span id="archk-star-wrapper">
                                <i onClick={disabled ? null : () => onStarClicked(1)} data-number="1" className="archk-star fas fa-star mr-4 display-3 cursor-pointer" />
                                <i onClick={disabled ? null : () => onStarClicked(2)} data-number="2" className="archk-star fas fa-star mr-4 display-3 cursor-pointer" />
                                <i onClick={disabled ? null : () => onStarClicked(3)} data-number="3" className="archk-star fas fa-star mr-4 display-3 cursor-pointer" />
                                <i onClick={disabled ? null : () => onStarClicked(4)} data-number="4" className="archk-star fas fa-star mr-4 display-3 cursor-pointer" />
                                <i onClick={disabled ? null : () => onStarClicked(5)} data-number="5" className="archk-star fas fa-star mr-4 display-3 cursor-pointer" />
                            </span>
                        </CardBody>
                        
                        <CardFooter className="text-center">
                            <p className="mb-0"> <i className="fas fa-check mr-2 text-success " /> If you experience was great, select 5 stars above.</p>
                        </CardFooter>
        
                    </Card>

                </Col>
            </Row>
        </Container>
    )
}

export default Gate;