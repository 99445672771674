import { useCallback, useState } from 'react'

import { Card, CardFooter, CardHeader, CardTitle, Col, Container, Row, CardBody } from "reactstrap";

import ReviewGoodIcon from '../_images/ReviewGood.svg'

import _review_outcomes from '_functions/review_outcomes';

const ReviewGood = ({company, reviewLink, companyNameColor, parentDomain}) => {

    const [disabled, setDisabled] = useState(false);

    const onLeaveReview = useCallback(async () => {

        setDisabled(true)
        await _review_outcomes.create({
            company: company._id,
            review_link: reviewLink._id,
            status: 'redirected'
        })
        if(window.location !== window.parent.location) {
            window.parent.postMessage({
                type: 'zapReportsReviews',
                event: 'onClose'
            }, parentDomain)
            window.open(reviewLink.redirect_url);
        } else {
            window.location.href = reviewLink.redirect_url;
        }

    }, [reviewLink.redirect_url, reviewLink._id, company._id, parentDomain])

    return (
        <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">
        
                    <Card className="bg-white border-0 mb-0 position-relative" >
        
                        <img src={ReviewGoodIcon} className="bg-white flow-icon" alt="..." />
        
                        <CardHeader>
                            <CardTitle className="mb-0 flow-icon-header">That's Great To Hear!</CardTitle>
                        </CardHeader>
        
                        <CardBody>
                            <p>Our goal is to help as many people as we can and we would greatly appreciate you  <span className="text-underline text-info font-weight-bold" onClick={onLeaveReview}>clicking the link</span> below to leave us a review.</p>
                            <p className="mb-0">It only takes a minute and your testimonial means everything to us.</p>
                        </CardBody>
                        
                        <CardFooter className="text-center">
                            <button disabled={disabled} onClick={onLeaveReview} className="btn btn-success btn-lg btn-block">
                                <i className="archk-arrow-moving fas fa-arrow-right mr-2 " />{' '}
                                Click HERE To Leave Us A Review{' '}
                                <i className="archk-arrow-moving fas fa-arrow-left ml-2 " />
                            </button>
                        </CardFooter>
        
                    </Card>

                    <p  className="text-sm text-center mt-3 font-weight-bold" style={{color: companyNameColor}}>Thank You!</p>

                </Col>
            </Row>
        </Container>
    )
}

export default ReviewGood;