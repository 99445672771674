import { Redirect } from 'react-router-dom';
import { useState, useEffect, useCallback } from "react";
import { Helmet } from 'react-helmet';

import { Col, Container, Row } from "reactstrap";
import Circle from 'components/markup/loading/Circle'

import UtilityAccessDenied from './Utility/AccessDenied'
import UtilityError from './Utility/Error'
import UtilityCloseIframe from './Utility/CloseIframe'

import Gate from './Gate'
import ReviewGood from './ReviewGood'
import ReviewBad from './ReviewBad'

import _review_links from '_functions/review_links'
import _review_outcomes from '_functions/review_outcomes';

const Reviews = ({match}) => {

    const [disabled,    setDisabled]    = useState(false);
    const [parentDomain,    setParentDomain]    = useState(false);
    const [isIframe,        setIsIframe]        = useState(false);
    const [err,             setErr]             = useState(false);
    const [accessDenied,    setAccessDenied]    = useState(false);

    const [view,            setView]            = useState('Gate');

    const [reviewLink,      setReviewLink]      = useState({});
    const [company,         setCompany]         = useState({});

    const getRedirectURL = useCallback(() => {
        if(reviewLink.random_urls && reviewLink.random_urls.length) {
            return reviewLink.random_urls[Math.floor(Math.random() * reviewLink.random_urls.length)]
        }
        return reviewLink.redirect_url;
    }, [reviewLink.redirect_url, reviewLink.random_urls])
   
    const onStarClicked = useCallback(async (number) => {
        if(number >= 4) {
            setDisabled(true)
            await _review_outcomes.create({
                company: company._id,
                review_link: reviewLink._id,
                status: 'redirected'
            })

            if(window.location !== window.parent.location) {
                window.parent.postMessage({
                    type: 'zapReportsReviews',
                    event: 'onClose'
                }, parentDomain)
                window.open(getRedirectURL());
            } else {
                window.location.href = getRedirectURL();
            }
            return;
        }
        return setView('ReviewBad');
    }, [company._id, parentDomain, reviewLink, getRedirectURL])

    const onMessageReceived = useCallback((message) => {
        if(message && message.data && message.data.type === 'zapReportsReviews') {
            if(message.data.event === 'onLoad') {
                setParentDomain(message.data.data.domain)
            }
        }
    }, [])


    useEffect(() => {
        if(window.location !== window.parent.location) {
            setIsIframe(true);
            window.addEventListener("message", onMessageReceived)
        } 

        return () => {
            if(window.location !== window.parent.location) {
                setIsIframe(true);
                window.removeEventListener("message", onMessageReceived)
            } 
        }

    }, [onMessageReceived])
   
    useEffect(() => {
        const fetchCompany = async () => {

            let _id = match.params.review_link;


            const link = await _review_links.findById(_id);
            if(!link.success) return setErr(true)
            if(!link.data) return setCompany('not found')

            const company = link.data.company;

            if(company.branding && company.branding.color) {
                document.body.style.background = company.branding.color
            }
            setCompany(company)
            setReviewLink({ 
                random_urls: link.data.random_urls, 
                email_url: link.data.email_url, 
                redirect_url: link.data.redirect_url,
                _id: link.data._id
            })
            if(window.location !== window.parent.location) {    
                // if we are an iframe return access denied if the docmain has not been whitelisted
                // example whitelist: http://localhost:3000/
                var url = (window.location !== window.parent.location) ? document.referrer: document.location.href;
                if(!company.authorized_embed_domain || (company.authorized_embed_domain && !company.authorized_embed_domain.includes(url)) ) {
                    return setAccessDenied(true)
                }
            } 

        }

        fetchCompany()
    }, [match.params.review_link])

    if(accessDenied) return <UtilityAccessDenied isIframe={isIframe} parentDomain={parentDomain} />;
    if(company === 'not found') return <Redirect to="/" />;
    if(!company._id) return <div className="py-6"><Circle /></div>

    const logo = company.branding && company.branding.logo ? company.branding.logo : null;
    const companyNameColor = company.branding && company.branding.name_color ? company.branding.name_color : '#525f7f';
    const companyLogoWidth = company.branding && company.branding.logo_width ? parseInt(company.branding.logo_width) : 300;
    const hideName = company.branding && company.branding.hide_name ? company.branding.hide_name : false;

    return (
        <>

			<Helmet>
				<title>{company ? `${company.name} - Powered By Zap Reports` : 'Powered By Zap Reports'}</title>
				<meta name="description" content={company ? `${company.name} - Powered By Zap Reports` : 'Zap Reports'} />
			</Helmet>

            <UtilityCloseIframe isIframe={isIframe} parentDomain={parentDomain} />

            <div className="header  pt-5 pb-6 pb-md-7">
                <Container className="pb-4">
                    {view === 'ReviewGood' ? (
                        <div className="text-center mb-6 mb-md-4">
                            <i className="fas fa-check-circle text-success display-1 " />
                        </div>
                    ) : (
                        <div className="header-body text-center mb-7">
                            <Row className="justify-content-center">
                                <Col className="px-5" lg="6" md="8" xl="5">
                                    {logo ? <img style={{width:  companyLogoWidth, position: 'relative',}} alt="..." className={!hideName ? "mb-4" : null}  src={logo} /> : null}
                                    {!hideName || !logo ? (
                                        <h3 className="display-4 mb-0" style={{color: companyNameColor}}>{company.name}</h3>
                                    ) : null}
                                </Col>
                            </Row>
                        </div>
                    )}
                    
                </Container>
            </div>

            {err ? (
                <UtilityError />
            ) : view === 'Gate' ? (
                <Gate onStarClicked={onStarClicked} disabled={disabled} />
            ) : view === 'ReviewGood' ? (
                <ReviewGood parentDomain={parentDomain} reviewLink={reviewLink} companyNameColor={companyNameColor} company={company} />
            ) : view === 'ReviewBad' ? (
                <ReviewBad reviewLink={reviewLink} company={company} parentDomain={parentDomain} companyNameColor={companyNameColor} />
            ) : null}

		</>

    )
}

export default Reviews